import React from "react";
import { Box, SvgIcon } from "@mui/material";
import "./ToggleSwitch.scss";
import { Theme } from "@mui/material/styles";

interface ToggleSwitchProps {
  theme: Theme;
  onToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ theme, onToggle }) => {
  const isOn = theme.palette.mode === "light" ? false : true;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <SvgIcon
        sx={{
          color: theme.palette.text.primary,
          width: 20,
          height: 20,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="5" />
          <line x1="12" y1="1" x2="12" y2="3" />
          <line x1="12" y1="21" x2="12" y2="23" />
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
          <line x1="1" y1="12" x2="3" y2="12" />
          <line x1="21" y1="12" x2="23" y2="12" />
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
        </svg>
      </SvgIcon>
      <input
        className="react-switch-checkbox"
        checked={isOn}
        onChange={onToggle}
        id="react-switch-new"
        type="checkbox"
      />
      <label
        style={{
          background: theme.palette.mode === "light" ? "#c9d1d9" : "#8b949e",
        }}
        className="react-switch-label"
        htmlFor="react-switch-new"
      >
        <span
          style={{ background: theme.palette.background.default }}
          className="react-switch-button"
        />
      </label>
      <SvgIcon
        sx={{
          color: theme.palette.text.primary,
          width: 20,
          height: 20,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
        </svg>
      </SvgIcon>
    </Box>
  );
};

export default ToggleSwitch;
